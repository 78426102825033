import { defineStore } from 'pinia'
import { userStore } from './user'
import { PlatformName } from '~/src/platform/platform'

export const moderationStore = defineStore('moderation', {
  state: () => ({
    comments_for_mod: [],
    new_comments: [],
    comments_by_engagement: [],
    num_comments: 0,
    num_comments_for_mod: 0,
  }),
  actions: {
    async fetch_comments_for_moderation(
      platform: null | string,  page: null | string, size: null | string
    ) {
      const uStore = userStore()
      const config = useRuntimeConfig()
      const qry = new URLSearchParams({
        platform: platform || 'ALL',
        page: page || '1',
        size: size || '100'
      })

      if (!uStore.user?.uid) return

      const req = await fetch(
        `${config.public.MYTHOS_RUNNER}/moderation/comments?${qry.toString()}`,
        { headers: { idToken: uStore.user?.idToken || '' } }
      )
      const res = await req.json()

      if (res && res.items) {
        this.comments_for_mod = res.items
        this.num_comments_for_mod = res.total || 0
      }
    },
    async fetch_new_comments_posted(
      platform: null | string, page: null | string, size: null | string
    ) {
      const uStore = userStore()
      const config = useRuntimeConfig()
      const qry = new URLSearchParams({
        platform: platform || 'ALL',
        page: page || '1',
        size: size || '100',
      })

      if (!uStore.user?.uid) return

      const req = await fetch(
        `${config.public.MYTHOS_RUNNER}/moderation/comments/engagement?${qry.toString()}`,
        { headers: { idToken: uStore.user?.idToken || '' } }
      )
      const res = await req.json()

      if (res && res.items) {
        this.new_comments = res.items
        this.num_comments = res.total
      }
    },
    async fetch_comments_by_engagement(
      platform: null | string, page: null | string, size: null | string
    ) {
      const uStore = userStore()
      const config = useRuntimeConfig()
      const qry = new URLSearchParams({
        platform: platform || 'ALL',
        page: page || '1',
        size: size || '100'
      })

      if (!uStore.user?.uid) return

      const req = await fetch(
        `${config.public.MYTHOS_RUNNER}/moderation/comments/engagement?${qry.toString()}`,
        { headers: { idToken: uStore.user?.idToken || '' } }
      )
      const res = await req.json()

      if (res && res.items) {
        this.comments_by_engagement = res.items
      }
    },
    async approve_and_respond(comment_id: number, response: string) {
      const uStore = userStore()
      const config = useRuntimeConfig()

      if (!uStore.user?.uid) return

      const req = await fetch(
        `${config.public.MYTHOS_RUNNER}/moderation/approve/comments`, {
          headers: { idToken: uStore.user?.idToken || '', 'Content-Type': 'application/json' },
          method: 'PATCH',
          body: JSON.stringify({
            comment_id: comment_id,
            comment_response: response
          })
        }
      )
      const res = await req.json()
      await this.fetch_comments_for_moderation(null, null, null)
      return res
    }
  }
})
