import { type Unsubscribe, getAuth, onAuthStateChanged } from 'firebase/auth'
import { Account } from '~/src/account/account'
import { User } from '~/src/account/user'
import { Platform } from '~/src/platform/platform'
import { Workflow } from '~/src/workflow/workflow'
import { Flow } from '~/src/flow/flow'
import { moderationStore } from '~/stores/moderation'
import moment from 'moment'

let onAuthChanged: Unsubscribe

export function initializeMythos() {
  if (onAuthChanged)
    onAuthChanged()

  onAuthChanged = onAuthStateChanged(getAuth(), async (fbUser) => {
    const mStore = moderationStore()
    const publicAccessRoutes = ['login', 'register', 'forgot-password', 'reset', 'workflow-workflow_ref', 'threading-thread_ref']
    const redirectIfLoggedInRoutes = ['login', 'register', 'forgot']

    // if the visitor isn't logged in and they aren't in a public page -> redirect
    if (!fbUser && !publicAccessRoutes.includes(useRoute().name?.toString() || ''))
      await navigateTo({ name: 'login' })

    // if the visitor is logged in -> initialize the app & redirect if necessary
    if (fbUser) {
      const user = new User(null, null, null, null)
      const account = new Account(null, null, null, null, null)
      const workflow = new Workflow(null, null, null, null, new Date(), new Date(), null, null, null)
      const platform = new Platform(null, null, null, null, null, null, null)
      const flow = new Flow(null, null, null, null, new Date(), new Date())

      try {
        // authenticate on server
        // initialize user
        await user.initialize(fbUser)

        // initialize account
        await account.initialize()

        // fetch all workflows, posts, platforms and flows
        workflow.initialize()
        platform.initialize()
        flow.initialize()

        // fetch comments for moderation
        mStore.fetch_comments_for_moderation(null,  null, null)
        mStore.fetch_new_comments_posted(null,  null, null)
        mStore.fetch_comments_by_engagement(null, null, null)
      }
      catch (error) {
        console.error(error)
        return await navigateTo({ name: 'login' })
      }

      // redirect if an authorized user is in login, forgot password page, etc.
      if (redirectIfLoggedInRoutes.includes(useRoute().name?.toString() || ''))
        return await navigateTo({ name: 'index' })
    }
  })
}
